import React from 'react';
import {
    ReferenceArrayField,
    ChipField,
    SingleFieldList,
    DateTimeInput,
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    TextField,
    EditButton,
    TextInput,
    SelectInput,
    ReferenceArrayInput,
    SelectArrayInput,
    SimpleList,
    required,
    DateField,
    Pagination,
    Toolbar,
    SaveButton,
    DeleteButton,
    AutocompleteArrayInput,
    useRefresh,
    BooleanInput
} from 'react-admin';
import PageviewIcon from '@mui/icons-material/Pageview';
import Actions from '../components/reactAdmin/Actions';
import { Grid, useMediaQuery } from '@material-ui/core';
import {translate, locale} from "../i18n/customI18nProvider";
import { Chip as MUIChip } from '@material-ui/core';
import { request } from '../util';


export const EntranceGroupIcon = PageviewIcon;

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const ZoneChipField = ({ record }) => {
    let label = '';
    if (record?.date) {
        label += (new Date(record.date * 1000)).toLocaleString() + ' - ';
    }
    label += (record?.zone_name?.[locale] ? record.zone_name[locale] : record?.zone_name?.fr) + (record?.zone_id ? (' ( ' + record.zone_id + ' ) ') : '');
    return (
        <MUIChip label={label} />
    );
};

export const EntranceGroupList = (props) => {
    
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    // let isSmall = false;
    // if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    //     || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0,4))) { 
    //     isSmall = true;
    // }
    
    return (
        <List {...props}
            actions={<Actions />}
            pagination={<PostPagination />}
            perPage={100}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                />
            ) : (
                <Datagrid>
                    <TextField label={translate('custom.name')} source="name" />
                    <ReferenceArrayField  label={translate('custom.entrances')} reference="entrances" source="entrances_ids"  sortable={false} >
                        <SingleFieldList>
                            <ChipField source="name" />
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <ReferenceArrayField  label={translate('custom.zones')} reference="zones" source="zone_ids"  sortable={false} >
                        <SingleFieldList>
                            <ZoneChipField />
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <DateField label={translate('custom.start_date')} source="display_begin_date" showTime locales={translate('custom.OPTIONS.i18n_date_format')} />
                    <DateField label={translate('custom.end_date')} source="display_end_date" showTime locales={translate('custom.OPTIONS.i18n_date_format')} />
                    <EditButton />
                </Datagrid>
            )}
        </List>
    );
};

const PostTitle = ({ record }) => {
    return <span>{translate('custom.entrance_control')} {record ? `"${record.name}"` : ''}</span>;
};

let entrance_groups = [];

const validateEntranceGroupForm = async (values) => {
        
    const errors = {};

    let display_begin_date = new Date(values.display_begin_date);
    let display_end_date = new Date(values.display_end_date);
    if(display_begin_date > display_end_date) {
        errors['display_begin_date'] = translate('custom.begin_date_cannot_be_greater_than_end_date');
    }

    let found = entrance_groups.find((ent_gp) => ent_gp.name === values.name);
    if(found) {
        errors['name'] = translate('custom.this_name_is_already_taken');
    }
    
    return errors
}

const form = () => {
    return (
        <Grid container spacing={2} style={{width: '100%'}}>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <TextInput fullWidth source="name" label={translate('custom.name')}  validate={required()} autoFocus variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <DateTimeInput fullWidth label={translate('custom.start_date')} source="display_begin_date" locales="fr-CH" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <DateTimeInput fullWidth label={translate('custom.end_date')} source="display_end_date" locales="fr-CH" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <SelectInput fullWidth source="entrance_type"
                    variant="outlined"
                    choices={[
                        { id: 'in', name: 'In' },
                        { id: 'out', name: 'Out' },
                        { id: 'both', name: 'Both' },
                    ]}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <ReferenceArrayInput
                    fullWidth
                    label={translate('custom.entrances')}
                    reference="entrances"
                    source="entrances_ids"
                    filterToQuery={searchText => ({ name: searchText, type: ['entrance', 'unpaid'] })}
                >
                    <SelectArrayInput optionText="name" variant="outlined" />
                </ReferenceArrayInput>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <ReferenceArrayInput fullWidth  label={translate('custom.zones')} reference="zones" source="zone_ids" filter={{is_event_enabled: true, is_zone_enabled: true}}>
                    <SelectArrayInput
                        optionText={record => {
                            return (
                                record.infomaniak_event_name[locale] ? record.infomaniak_event_name[locale] : record.infomaniak_event_name.fr) + 
                                (record.infomaniak_event_id ? (' ( ' + record.infomaniak_event_id + ' ) ') : '') + 
                                ' - ' + 
                                (new Date(record.date * 1000)).toLocaleString() + 
                                ' - ' + 
                                (record.zone_name[locale] ? record.zone_name[locale] : record.zone_name.fr) + 
                                (record.zone_id ? (' ( ' + record.zone_id + ' ) ') : '');
                        }}
                        variant="outlined"
                    />
                </ReferenceArrayInput>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <BooleanInput source="allow_entrance_purchase" label={translate('custom.allow_entrance_purchase')} />
            </Grid>
        </Grid>
    );
}

const CustomEditToolbar = props => {
    return (
        <Toolbar {...props}>
            <SaveButton {...props}
                disabled={props.pristine || props.submitting}
                style={{marginRight: 'auto'}}
            />
            <DeleteButton mutationMode="pessimistic" />
        </Toolbar>
    )
};

export class EntranceGroupEdit extends React.Component {
    constructor() {
        super();

        entrance_groups = [];

    }

    render() {
        return (
            <Edit undoable={false} title={<PostTitle />} {...this.props}>
                <SimpleForm redirect="edit" variant="standard" warnWhenUnsavedChanges validate={validateEntranceGroupForm} toolbar={<CustomEditToolbar />} >
                    {form()}
                </SimpleForm>
            </Edit>
        );
    }
}

const SaveAndAddNewToolbar = (props) => {
    const refresh = useRefresh();
    return (
        <Toolbar {...props}>
            <SaveButton {...props}
                disabled={props.pristine || props.submitting}
                label="ra.action.save"
                redirect="edit"
                submitOnEnter={false}
                style={{marginRight: 10}}
            />
            <SaveButton {...props}
                disabled={props.pristine || props.submitting}
                label={translate('custom.save_and_add')}
                redirect={() => {
                    refresh();
                    return 'create';
                }}
                submitOnEnter={true}
            />
        </Toolbar>
    )
}

export class EntranceGroupCreate extends React.Component {
    constructor() {
        super();

        entrance_groups = [];

    }

    async componentDidMount() {
        try {
            let ent_gps = await request('/entrance_groups', 'GET', null);
            if(ent_gps) {
                if(!ent_gps.error) {
                    entrance_groups = ent_gps;
                    console.log(entrance_groups);
                }
            }

        } catch(e) {

        }
    }

    render() {
        return (
            <Create title={translate('custom.create_entrance_control')} {...this.props}>
                <SimpleForm variant="standard" warnWhenUnsavedChanges validate={validateEntranceGroupForm}
                    toolbar={<SaveAndAddNewToolbar />}
                >
                    {form()}
                </SimpleForm>
            </Create>
        );
    }
}